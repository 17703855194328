<template>
  <div class="filters" ref="filters" v-if="isLoaded">
    <div
      class="filters__item filters__item_first"
      v-show="hasActiveFilters"
    >
      <Button
        class="filters__reset"
        variant="secondary"
        shape="circle"
        size="sm"
        icon-left="close"
        mobile
        @click="onReset"
      ></Button>
    </div>
    <div
      class="filters__item"
      :class="{ 'filters__item_first': filter.val !== null }"
      v-for="(filter, key) in availableFilters"
      :key="key"
    >
      <Chip
        v-model="filter.val"
        :name="filter.name"
        :options="filter.options"
        :multiselect="filter.multiselect"
        v-show="filter.options"
        @change="onFilterChange"
        @rangeChange="onFilterRangeChange"
        :searchable="filter.searchable"
        :confirmable="filter.confirmable"
        :showLabel="filter.showLabel"
        :valueRange="filter.valueRange"
      >
      </Chip>
    </div>
  </div>
</template>

<script>
import Chip from './Chip.vue';

export default {
  name: 'Filters',
  components: {
    Chip,
  },
  props: {
    options: Object,
    hideDropdown: Boolean,
  },
  data() {
    return {
      filters: {
        category: {
          name: 'Категория',
          val: null,
          options: [],
          multiselect: true,
          confirmable: true,
          showLabel: true,
        },
        type: {
          name: 'Тип',
          val: null,
          options: [],
          multiselect: true,
          confirmable: true,
          showLabel: true,
        },
        partner: {
          name: 'Бренд',
          val: null,
          options: [],
        },
        price: {
          name: 'Цена',
          val: null,
          options: [],
          valueRange: true,
          confirmable: true,
        },
        discipline: {
          name: 'Активность',
          val: null,
          options: [],
          multiselect: true,
          confirmable: true,
          showLabel: true,
        },
        gender: {
          name: 'Пол',
          val: null,
          options: [],
        },
        age: {
          name: 'Возраст',
          val: null,
          valueRange: true,
          options: [],
          confirmable: true,
        },
        teamIds: {
          name: 'Команда',
          val: null,
          options: [],
        },
        dateEvent: {
          name: 'Дата проведения',
          val: null,
          options: [],
        },
      },
      availableFilters: {},
      isLoaded: false,
    };
  },
  computed: {
    hasActiveFilters() {
      return Object.keys(this.availableFilters).some((key) => this.availableFilters[key].val);
    },
  },
  watch: {
    hideDropdown(val) {
      if (val) {
        const { filters } = this.$refs;
        if (filters) filters.click();
      }
    },
  },
  methods: {
    onFilterChange() {
      this.setQuery();
      this.$emit('change', this.availableFilters);
    },
    onFilterRangeChange() {
      this.setQuery();
      this.$emit('change', this.availableFilters);
    },
    onReset() {
      this.reset();
      this.$emit('reset');
    },
    reset() {
      const filters = this.availableFilters;
      Object.keys(filters)
        .forEach((key) => {
          filters[key].val = null;
        });
      this.setQuery();
    },
    setQuery() {
      const filters = this.availableFilters;
      const query = { ...this.$route.query };
      Object.keys(filters)
        .forEach((key) => {
          if (typeof filters[key].val === 'object') {
            query[key] = JSON.stringify(filters[key].val || undefined);
          } else {
            query[key] = filters[key].val || undefined;
          }
        });
      this.$router.replace({ query })
        .catch(() => { });
    },
    parseQuery() {
      const filters = this.availableFilters;
      const { query } = this.$route;
      Object.keys(filters).forEach((q) => {
        const option = query[q]
          ? filters[q].options.find((o) => o.id === query[q])
          : null;
        if (option) {
          filters[q].val = query[q];
          return;
        }
        if (q !== 'partner') {
          try {
            const val = JSON.parse(query[q]);
            filters[q].val = val;
          } catch { /* */ }
        }
      });
    },
    mapOptions(options, idKey, nameKey) {
      if (!options || !options.length) {
        return [];
      }
      return options.map((o) => (
        typeof o === 'object'
          ? {
            id: o[idKey || 'id'],
            name: o[nameKey || 'name'],
            value: o.value || '',
          }
          : {
            id: o,
            name: o,
          }
      ));
    },
    setData(data) {
      this.availableFilters = {};
      if (!data) {
        this.filters.category.options = [];
        this.filters.type.options = [];
        this.filters.partner.options = [];
        this.filters.price.options = [];
        this.filters.discipline.options = [];
        this.filters.teamIds.options = [];
        this.filters.gender.options = [];
        this.filters.age.options = [];
        this.filters.dateEvent.options = [];
      } else {
        this.filters.category.options = this.mapOptions(data.category || []);
        this.filters.type.options = this.mapOptions(data.type || []);
        this.filters.partner.options = this.mapOptions(data.partner || []);
        this.filters.price.options = this.mapOptions(data.price || []);
        this.filters.discipline.options = this.mapOptions(data.discipline || []);
        this.filters.teamIds.options = this.mapOptions(data.teamIds || []);
        this.filters.gender.options = this.mapOptions(data.gender || []);
        this.filters.age.options = this.mapOptions(data.age || []);
        this.filters.dateEvent.options = this.mapOptions(data.dateEvent || []);
      }

      Object.keys(this.filters)
        .forEach((key) => {
          const filter = this.filters[key];
          filter.searchable = filter.options.length > 5;
          if (filter.options.length > 1) {
            this.availableFilters[key] = filter;
          }
        });

      this.parseQuery();
      this.isLoaded = true;
      this.$emit('change', this.availableFilters);
    },
  },
  mounted() {
    this.setData(this.options);
  },
};
</script>

<style lang="scss" scoped>
  @import "./Filters";
</style>
