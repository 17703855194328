<template>
  <div class="participant-item">
    <div v-if="!isMobileApp" class="divider"></div>
    <div v-if="!isMobileApp" class="participants__menu">
      <Menu />
      <div class="divider"></div>
    </div>
    <div class="participant-item__wrapper">
      <div class="participant-item__card">
        <div class="participant-item__card-prev">
          <Button
            variant="secondary"
            :size="$root.isMobile ? 'sm' : 'lg'"
            shape="circle"
            iconLeft="left"
            mobile
            @click="pagePrev"
          ></Button>
        </div>
        <div class="participant-item__card-upload">
          <Button
            variant="secondary"
            :size="$root.isMobile ? 'sm' : 'lg'"
            shape="circle"
            iconLeft="upload"
            mobile
            id="menuButton"
          ></Button>
          <Dropdown
            triggerId="menuButton"
            placement="bottom"
            listeners="click"
            hide-overlay
            auto-width
          >
            <template slot="items">
              <div class="base-dropdown__item participant__dropdown participant__dropdown--links">
                <p class="participant__dropdown-title">Поделиться</p>
                <div @click="copyUrlToClipboard" class="participant__dropdown-item participant__dropdown-item--one">
                  <span class="participant__dropdown-link">
                    <span>Скопировать ссылку</span>
                    <Icon name="copy" size="xs" />
                  </span>
                </div>
              </div>
            </template>
          </Dropdown>

        </div>
        <div v-if="participantData" class="participant-item__card-header">
          <div class="participant-item__card-img">
            <div class="participant-item__card-avatar" :style="{ 'background-image': `url(${getAvatarImage(participantData.avatar)})`, 'background-color': participantData.avatar ? '#c7c0c0' : ''}">
            </div>
            <div class="participant-item__card-level">{{ participantData.level }} ур.</div>
          </div>
          <div v-if="participantData.firstName || participantData.lastName" class="participant-item__card-info">
            <p class="participant-item__card-name">{{ participantData.firstName }} {{ participantData.lastName }}</p>
            <div class="participant-item__card-row">
              <div :class="{'male' : participantData.gender === 'Male', 'female' : participantData.gender === 'Female'}" v-if="participantData.gender">
                <Icon v-if="participantData.gender === 'Male'" name="male" size="xxs" />
                <Icon v-if="participantData.gender === 'Female'" name="female" size="xxs" />
              </div>
              <p v-if="participantData.birthday" class="participant-item__card-age">{{ calculateAge(participantData.birthday) }}</p>
              <p class="participant-item__card-city">{{ participantData && participantData.city ? participantData.city : '' }}</p>
            </div>
            <div class="participant-item__card-team">
              <div class="participant-item__card-team-avatar" >
                <img v-if="participantData.team && participantData.team.logo" img :src="participantData.team.logo" alt="" />
                <Icon v-else name="user" size="xs" />
              </div>
              <p :class="{'team' : participantData.team && participantData.team.logo}" class="participant-item__card-team-title">{{ participantTeamName }}</p>
            </div>
            <Button
              variant="secondary"
              size="lg"
              tag="a"
              mobile
              wide
              :iconLeft="iconButton"
              text="Написать"
              id="showLevelInfo"
              :disabled="!participantAccountVk && !participantAccountTelegram"
            />
            <Dropdown
              triggerId="showLevelInfo"
              :placement="$root.isDesktop ? 'top-end' : 'top'"
              listeners="click"
              hide-overlay
              auto-width
            >
            <template slot="items">
              <div class="base-dropdown__item participant__dropdown">
                <p class="participant__dropdown-title">Написать участнику</p>
                <ul class="participant__dropdown-list">
                  <li v-if="participantAccountVk" class="participant__dropdown-item" :class="{ 'participant__dropdown-item--one': !participantAccountTelegram }">
                    <a :href="participantAccountVk" target="_blank" class="participant__dropdown-link">
                      <span>Вконтакте</span>
                      <Icon name="vk" size="m" />
                    </a>
                  </li>
                  <li v-if="participantAccountTelegram" class="participant__dropdown-item" :class="{ 'participant__dropdown-item--one': !participantAccountVk }">
                    <a :href="`https://t.me/${participantAccountTelegram}`" target="_blank" class="participant__dropdown-link">
                      <span>Telegram</span>
                      <Icon name="telegram" size="m" />
                    </a>
                  </li>
                </ul>
              </div>
            </template>
          </Dropdown>
        </div>
      </div>
      <Tabs
        class="participant-item__tabs"
        :items="tabs"
        :activeItem="selectedTab"
        @changed="onChangeTab"
      />
      <div class="participant-item__content">
        <div v-if="isVisible(0)">
        <div class="activity" id="dashboard-activity">
        <div class="activity__loader" v-if="loading">
          <Loader size="24" />
        </div>
        <div class="activity__slider">
          <Slider
            v-if="!loading && activities && activities.length > 0"
            cols="1"
            :navigationOffset="activityNavigationOffset"
            :paginationOffset="activityPaginationOffset"
            :slidesPerView="1"
            :autoHeight="true"
            class="activity-slider"
            @indexChanged="onSliderIndexChaged"
          >
            <ActivityCard
              v-for="(activity, i) in activities"
              :key="i"
              :title="activity.date"
              :items="activity.items"
            ></ActivityCard>
          </Slider>
        </div>
          </div>
        </div>
        <div v-if="isVisible(1)">
          <div class="nominations">
            <div class="activity__loader" v-if="loading">
            <Loader size="24" />
          </div>
        <div v-if="!loading" class="nominations__types">
          <Button
            v-for="(item, i) in nominationTypes"
            :key="i"
            :variant="i === selectedNominationType ? 'primary' : 'secondary'"
            :disabled="isRatingTypeDisabled(i)"
            size="md"
            mobile
            @click="onChangeNominationType(i)"
          >
            {{ item }}
          </Button>
        </div>
        <div
          class="nominations__slider-container"
          :style="{
            'opacity': (hiddenNominationsSlider ? '0' : '1'),
            'min-height': (nominationsSliderHeight ? nominationsSliderHeight + 'px' : 'auto'),
          }"
          :id="nominationsSliderId"
        >
          <div v-if="!nominationsUpdating && selectedNominationType === 0 && !loading"  class="nominations__slider">
            <Slider
              v-if="participantRatings"
              cols="1"
              :slidesPerView="1"
              :loop="true"
              :navigationOffset="nominationsNavigationOffset"
              :paginationOffset="nominationsPaginationOffset"
              class="nominations-slider"
            >
              <NominationCard
                v-for="item in participantRatings ? participantRatings : []"
                :key="item.nominationId"
                type="personal"
                :state="nominationsState"
                :image="getNominationImage(item)"
                :nominationText="getNominationDates(item)"
                :lockedText="{ text: 'Для участия необходимо', link: 'подключить трекер' }"
                :title="item.title"
                :rules="{ text: 'Правила', action: () => onShowRules(item) }"
                :prizes="{ text: 'Награды', action: () => onShowPrizes(item) }"
                :ratingText="{ general: 'Текущий рейтинг', personal: 'Вы в рейтинге' }"
                :values="getNominationRating(item)"
                @show-details="onShowRating(item)"
                @locked-click="onShowOnboarding()"
              ></NominationCard>
            </Slider>
          </div>
          <div v-if="!nominationsUpdating && selectedNominationType === 1 && !loading"  class="nominations__slider">
            <Slider
              cols="1"
              :slidesPerView="1"
              :loop="true"
              :navigationOffset="nominationsNavigationOffset"
              :paginationOffset="nominationsPaginationOffset"
              filterByGroupId="1"
              class="nominations-slider"
            >
              <NominationCard
                v-for="item in  participantTeamRatings && participantTeamRatings.length > 0 ? participantTeamRatings :  []"
                :key="item.nominationId"
                :image="getNominationImage(item)"
                type="personal"
                state="in-progress"
                class="team-rating"
                data-group-id="1"
                :nominationText="getNominationDates(item)"
                :lockedText="{ text: 'Для участия необходимо', link: 'подключить трекер' }"
                :title="item.title"
                :rules="{ text: 'Правила', action: () => onShowRules(item) }"
                :prizes="{ text: 'Награды', action: () => onShowPrizes(item) }"
                :ratingText="{ general: 'Текущий рейтинг', personal: 'Команда в рейтинге' }"
                :values="getNominationRating(item, true)"
                @show-details="onShowRating(item, true)"
              ></NominationCard>
            </Slider>
          </div>
        </div>
      </div>
        </div>
        <div v-if="isVisible(2)">
          <div class="activity__loader" v-if="loading">
            <Loader size="24" />
          </div>
          <div v-else>
            <div class="participant-item__achievements">
              <AchievementsParticipant
                :achievements="participantAchievements"
                :isMobileApp="isMobileApp"
                :sportIndexes="participantSportIndexes"
              />
            </div>
          <div v-if="statistics" class="participant-item__activities">
            <Metric
              v-for="(item, i) in statistics"
              :key="i"
              :icon="item.icon"
              :value="item.value"
              :subtitle="item.subtitle"
            />
            </div>
            <div class="participant-item__graph">
              <div class="goal__compare">
                <div class="compare__text" v-html="distanceText"></div>
                <div class="compare__text" v-html="compareText"></div>
                <div
                  class="compare__graph"
                  :style="{ 'background-image': `url('${ $root.isMobile ? goalGraphMobile : goalGraph }')` }"
                >
                  <img
                    :src="goalTriangle"
                    class="graph__marker"
                    :style="{
                      'left': `calc(${ Math.round(participantDistance.contributionPercentage)-1 }%)`,
                      'bottom': `calc(${ goalCoords[Math.round(participantDistance.contributionPercentage)] }px + 8px)`,
                    }"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <RulesPrizesModal
      :active="rulesPrizesModal"
      :image="rulesPrizesImage"
      :title="rulesPrizesTitle"
      :subtitle="rulesPrizesSubtitle"
      :nominationId="rulesPrizesNominationId"
      :isTeam="rulesPrizesIsTeam"
      state="personal"
      :tabs="rulesPrizesTabs"
      :activeTab="rulesPrizesTabActive"
      :isMobileApp="isMobileApp"
      @close="onRulesPrizesClosed"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import dayjs from 'dayjs';
import NominationCard from '@sc-component-library/nomination-card/src/main';
import Metric from '@rr-component-library/metric/src/main';
import Menu from '@/components/Menu/Menu.vue';
import Dropdown from '@/components/Dropdown/Dropdown.vue';
import Avatars from '@/assets/data/avatars';
import ActivityCard from '@/components/ActivityCard/ActivityCard.vue';
import ActivityCover3 from '@/assets/img/activity-card/updated.svg';
import ActivityCoverXP from '@/assets/img/activity-card/xp.svg';
import RulesPrizesModal from '@/components/RulesPrizesModal/RulesPrizesModal.vue';
import AchievementsParticipant from '@/components/AchievementsParticipant/AchievementsParticipant.vue';
import AchievementsAll from '@/assets/data/achievements';
import GoalCoords from '@/assets/data/goalCoords';
import {
  scrollToId, addNonBreakingSpaces, formatDateRange, formatNumber,
} from '@/assets/js/helpers';

export default {
  name: 'ParticipantItem',
  components: {
    Menu,
    Dropdown,
    ActivityCard,
    NominationCard,
    RulesPrizesModal,
    AchievementsParticipant,
    Metric,
  },
  data() {
    return {
      achievementsAll: AchievementsAll,
      goalCoords: GoalCoords,
      selectedTab: 0,
      participantData: {},
      participantId: '',
      activities: null,
      loading: true,
      tabs: [
        { title: 'Активность', enabled: true },
        { title: 'Рейтинги', enabled: true },
        { title: 'Статистика', enabled: true },
      ],
      url: window.location.href,
      nominationsUpdating: false,
      nominationsSliderId: `nominations__slider-container${Math.random().toString().replace('.', '')}`,
      nominationsSliderHeight: 0,
      hiddenNominationsSlider: false,
      selectedNominationType: null,
      nominationTypes: ['Личный', 'Командный'],
      nominationsState: 'in-progress',
      rulesPrizesModal: false,
      rulesPrizesImage: '',
      rulesPrizesTitle: '',
      rulesPrizesSubtitle: '',
      rulesPrizesNominationId: '',
      rulesPrizesIsTeam: false,
      rulesPrizesTabActive: 0,
      ratingActive: false,
      statistics: [],
      contributionPercentage: 0,
      totalDistance: '',
      rulesPrizesTabs: [
        {
          title: 'Правила',
          enabled: true,
          content: '',
        },
        {
          title: 'Награды',
          enabled: true,
          content: '',
        },
        {
          title: 'Рейтинг',
          enabled: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters('user', ['isMobileApp']),
    ...mapGetters('settings', ['theme', 'themeDone', 'options', 'resources']),
    ...mapGetters('communications', ['participant', 'participantOperationshistory', 'participantRatings', 'participantTeamRatings', 'participantStatistics', 'participantAchievements', 'participantSportIndexes', 'participantDistance']),
    // ...mapGetters('achievements', ['achievements']),
    ...mapGetters('trainings', ['sportIndexes']),
    formattedActivities() {
      const activities = [...this.statistics];
      console.log(activities);

      for (let i = 0; i < this.statistics ? activities.length : 0; i += 1) {
        activities[i].value = formatNumber(activities[i].value, '');
      }
      return activities;
    },
    participantAccountVk() {
      return this.participant ? this.participant.accounts.vk : false;
    },
    participantAccountTelegram() {
      return this.participant ? this.participant.accounts.telegram : false;
    },
    participantTeamName() {
      return this.participant && this.participant.team ? this.participant.team.name : 'Без команды';
    },
    iconButton() {
      if (this.participantAccountVk && this.participantAccountTelegram) {
        return 'chat-bubble';
      }
      if (this.participantAccountVk) {
        return 'vk';
      }
      if (this.participantAccountTelegram) {
        return 'telegram';
      }
      return '';
    },
    activityNavigationOffset() {
      return {
        default: '0', md: '0', lg: '0', xl: '1',
      };
    },
    activityPaginationOffset() {
      return {
        default: '8', md: '12', lg: '12', xl: '28',
      };
    },
    nominationsNavigationOffset() {
      return {
        default: '0', md: '0', lg: '0', xl: '10',
      };
    },
    nominationsPaginationOffset() {
      return {
        default: '8', md: '12', lg: '12', xl: '28',
      };
    },
    isTeamsEnabled() {
      return this.options ? this.options.isTeamsEnabled === true : false;
    },
    showTeamRatings() {
      return this.options ? this.options.showTeamRatings === true : false;
    },
    goalGraph() {
      return this.resources ? this.resources.goalGraph || '' : '';
    },
    goalGraphMobile() {
      return this.resources ? this.resources.goalGraphMobile || '' : '';
    },
    goalTriangle() {
      return this.resources ? this.resources.goalTriangle || '' : '';
    },
    compareText() {
      return addNonBreakingSpaces(`Вклад больше, чем у <span class="participant-item__text">${formatNumber(Math.round(this.participantDistance ? this.participantDistance.contributionPercentage : 0), '%')}</span> участников`);
    },
    distanceText() {
      return addNonBreakingSpaces(`Вклад в общую цель: <span>${formatNumber(this.participantDistance ? this.participantDistance.totalDistance : 0, 'км')}.</span>`);
    },
  },
  watch: {
    nominationsUpdating(val) {
      if (val) this.hiddenNominationsSlider = true;
      else {
        setTimeout(() => {
          this.nominationsSliderHeight = 0;
          this.hiddenNominationsSlider = false;
        }, 500);
      }
    },
  },
  methods: {
    ...mapActions('user', ['getProfile']),
    ...mapActions('wallet', ['getBalance']),
    ...mapActions('communications', ['getParticipant', 'getActivityOperations', 'getParticipantRatings', 'getParticipantTeamRatings', 'getParticipantStatistic', 'getParticipantAchievement', 'getParticipantSportIndex', 'getParticipantDistance']),
    // ...mapActions('achievements', ['getAchievements']),
    ...mapActions('trainings', ['getIndexes']),
    getFormattedDistance(value) {
      if (!value || !value.attributes) return '';
      const distance = parseInt(value.attributes.Distance, 10);
      const unit = distance >= 1000 ? 'км' : 'м';
      const formatted = distance >= 1000 ? Math.round(distance / 1000) : distance;
      if (value.attributes.Discipline === 'Walking') return `${formatted} ${unit} ходьба`;
      if (value.attributes.Discipline === 'Running') return `${formatted} ${unit} бег`;
      if (value.attributes.Discipline === 'Cycling') return `${formatted} ${unit} велозаезд`;
      if (value.attributes.Discipline === 'Swimming') return `${formatted} ${unit} плавание`;
      if (value.attributes.Discipline === 'Skiing') return `${formatted} ${unit} лыжи`;
      return '';
    },
    setActivities() {
      if (!this.participantStatistics) {
        this.statistics = [];
        return;
      }
      if (this.participantStatistics) {
        const activities = [];
        if (this.participantStatistics.Walking) {
          const walkingDistance = this.participantStatistics.Walking.distance;
          activities.push({
            icon: 'walking',
            value: String(walkingDistance >= 1000 ? Math.round(walkingDistance / 1000) : walkingDistance),
            subtitle: `${walkingDistance >= 1000 ? 'км' : 'м'}, ходьба`,
          });
        }
        if (this.participantStatistics.Running) {
          const runningDistance = this.participantStatistics.Running.distance;
          activities.push({
            icon: 'running',
            value: String(runningDistance >= 1000 ? Math.round(runningDistance / 1000) : runningDistance),
            subtitle: `${runningDistance >= 1000 ? 'км' : 'м'}, бег`,
          });
        }
        if (this.participantStatistics.Cycling) {
          const cyclingDistance = this.participantStatistics.Cycling.distance;
          activities.push({
            icon: 'cycling',
            value: String(cyclingDistance >= 1000 ? Math.round(cyclingDistance / 1000) : cyclingDistance),
            subtitle: `${cyclingDistance >= 1000 ? 'км' : 'м'}, вело`,
          });
        }
        if (this.participantStatistics.Swimming) {
          const swimmingDistance = this.participantStatistics.Swimming.distance;
          activities.push({
            icon: 'swimming',
            value: String(swimmingDistance >= 1000 ? Math.round(swimmingDistance / 1000) : swimmingDistance),
            subtitle: `${swimmingDistance >= 1000 ? 'км' : 'м'}, плавание`,
          });
        }
        if (this.participantStatistics.Skiing) {
          const skiingDistance = this.participantStatistics.Skiing.distance;
          activities.push({
            icon: 'skiing',
            value: String(skiingDistance >= 1000 ? Math.round(skiingDistance / 1000) : skiingDistance),
            subtitle: `${skiingDistance >= 1000 ? 'км' : 'м'}, лыжи`,
          });
        }
        this.statistics = activities;
      }
    },
    getNominationRating(item, isTeam = false) {
      if (!item) return [];

      const rating = [];
      const leaders = item && item.leaders ? item.leaders : [];
      for (let i = 0; i < leaders.length; i += 1) {
        const it = {
          number: item.leaders[i].position.toString(),
          name: isTeam ? item.leaders[i].teamName : `${item.leaders[i].lastName} ${item.leaders[i].firstName}`,
          value: item.leaders[i].points.toString(),
          current: isTeam ? item.leaders[i].isCurrentTeam === true : item.leaders[i].isCurrentUser === true,
        };
        rating.push(it);
      }
      return rating;
    },
    onShowPrizes(item) {
      this.rulesPrizesTabs[0].content = item.rules || '';
      this.rulesPrizesTabs[1].content = item.awards || '';
      this.rulesPrizesTabs[2].enabled = false;
      this.rulesPrizesTabActive = 1;
      this.rulesPrizesImage = this.getNominationImage(item);
      this.rulesPrizesTitle = this.getNominationDates(item);
      this.rulesPrizesSubtitle = item.title;
      this.rulesPrizesModal = true;
    },
    onShowRating(item, isTeam = false) {
      this.rulesPrizesTabs[0].content = item.rules || '';
      this.rulesPrizesTabs[1].content = item.awards || '';
      this.rulesPrizesTabs[2].enabled = true;
      this.rulesPrizesTabActive = 2;
      this.rulesPrizesImage = this.getNominationImage(item);
      this.rulesPrizesTitle = this.getNominationDates(item);
      this.rulesPrizesSubtitle = item.title;
      this.rulesPrizesNominationId = item.nominationId;
      this.rulesPrizesIsTeam = isTeam;
      this.rulesPrizesModal = true;
    },
    onRulesPrizesClosed() {
      window.location.hash = '';
      this.rulesPrizesModal = false;
    },
    getNominationDates(item) {
      if (!item) return '';
      const startDate = dayjs.utc(item.startDate);
      const finishDate = dayjs.utc(item.finishDate);
      if (startDate.isValid() && finishDate.isValid()) {
        return formatDateRange({ start: startDate, finish: finishDate, showYear: true });
      }
      return '';
    },
    getNominationImage(item) {
      if (!item || !item.images) return '';
      return this.$root.isDesktop ? item.images.desktop || '' : item.images.mobile || '';
    },
    isRatingTypeDisabled(index) {
      if (index === 0) {
        if (this.participantRatings ? this.participantRatings.length === 0 : true) return true;
      }
      if (index === 1) {
        if (this.isTeamRatingsDisabled()) return true;
        if (this.participantTeamRatings ? this.participantTeamRatings.length === 0 : true) return true;
      }
      return false;
    },
    onChangeNominationType(index) {
      if (this.selectedNominationType === index) return;
      this.updateHeight();
      this.selectedNominationType = -1;
      setTimeout(() => { this.nominationsUpdating = true; }, 100);
      setTimeout(() => {
        this.selectedNominationType = index;
        this.nominationsUpdating = false;
      }, 400);
    },
    updateHeight() {
      const itemsSlider = document.getElementById(this.nominationsSliderId);
      if (itemsSlider === null) return;
      if (itemsSlider.offsetHeight > 0) {
        this.nominationsSliderHeight = itemsSlider.offsetHeight;
      }
    },
    isTeamRatingsDisabled() {
      return !this.isTeamSet && this.disableTeamsForGuest;
    },

    onSliderIndexChaged() {
      scrollToId('dashboard-activity');
    },
    activityCardTitle() {
      return addNonBreakingSpaces('Более ранние записи в разделе <a href="/activities">«Активность»</a>');
    },
    hasSameTimeAndType(operation1, operation2) {
      if (!operation1 || !operation2) {
        return false;
      }
      if (operation1.operationType !== 'SportActivity' || operation2.operationType !== 'SportActivity') {
        return false;
      }
      const date1 = dayjs(operation1.operationDate);
      const date2 = dayjs(operation2.operationDate);
      if (!date1.isValid() || !date2.isValid()) {
        return false;
      }
      return date1.isSame(date2, 'second');
    },
    filterOperationsByTime(operations) {
      const filteredOperations = [];
      let previousOperation = null;
      for (let i = 0; i < operations.length; i += 1) {
        const currentOperation = operations[i];
        if (previousOperation && this.hasSameTimeAndType(previousOperation, currentOperation)) {
          previousOperation.title += `, ${this.getFormattedDistance(currentOperation)}`;
        } else {
          filteredOperations.push(currentOperation);
          previousOperation = currentOperation;
        }
      }
      return filteredOperations;
    },
    getMapppedOperations(operations) {
      if (!operations) return [];
      const filteredOperations = this.filterOperationsByTime(operations);
      const days = [];
      let currentDay = { date: '', items: [] };
      let sportActivities = [];
      let lastDate = '';
      for (let i = 0; i < filteredOperations.length; i += 1) {
        const value = filteredOperations[i];
        const date = dayjs.utc(value.operationDate).local();
        if (!date.isValid()) continue;
        const formattedDate = date.format('D MMMM');
        const item = this.getMappedItem(value);
        const currentDate = date.format('YYYY-MM-DD');
        if (currentDate !== lastDate) {
          if (lastDate !== '') {
            days.push(currentDay);
          }
          currentDay = { date: formattedDate, items: [] };
          sportActivities = [];
        }
        if (value.operationType === 'SportActivity') {
          const existingSportActivity = sportActivities.find((activity) => activity.time === item.time);
          if (existingSportActivity) {
            existingSportActivity.titles = existingSportActivity.titles || [];
            existingSportActivity.titles.push(item.title);
            existingSportActivity.title = `Получены новые тренировки: ${new Intl.ListFormat('ru').format(existingSportActivity.titles)}`;
          } else {
            item.titles = [item.title];
            item.title = `Получены новые тренировки: ${item.title}`;
            currentDay.items.push(item);
            sportActivities.push(item);
          }
        } else {
          currentDay.items.push(item);
        }
        lastDate = currentDate;
      }
      if (currentDay.items.length > 0) {
        days.push(currentDay);
      }
      return days;
    },
    getMappedItem(value) {
      const item = {};
      if (value.operationType === 'TeamParticipant') {
        item.time = dayjs.utc(value.operationDate).local().format('HH:mm');
        item.img = value.attributes.TeamLogo || '';
        if (value.attributes.StateOperation === 'Created') {
          item.title = `Вы присоединились к команде <a href="/my-team">${value.attributes.TeamName}`;
        } else {
          item.title = `Вы покинули команду <span class="team-out">${value.attributes.TeamName}</span>`;
          item.isTaskRejected = true;
        }
        return item;
      }
      if (value.operationType === 'Purchase') {
        item.img = value.attributes.ProductMobileImageUrl || '';
        item.isInternalImg = false;
        item.title = `Вы купили <a href="/market/reward?id=${value.attributes.PromotionId || ''}">${value.attributes.ProductName}`;
        item.time = dayjs.utc(value.operationDate).local().format('HH:mm');
        item.coins = -Math.round(value.attributes.Amount);
        return item;
      }
      if (value.operationType === 'ParticipantExercise') {
        if (!['RejectedManually', 'Rejected'].includes(value.attributes.StateOperation)) {
          item.img = value.attributes.ExerciseMobileImageUrl || '';
          item.isInternalImg = false;
          item.title = `${this.getFormattedTaskState(value)} <a href="#!showTask=${value.sourceId}&peId=${value.operationSourceId}">${value.attributes.ExerciseTitle}</a>`;
          item.time = dayjs.utc(value.operationDate).local().format('HH:mm');
          if (value.attributes.StateOperation === 'Completed') {
            item.coins = `+${Math.round(value.attributes.Reward)}`;
          }
          item.points = 0;
          return item;
        }
        item.img = value.attributes.ExerciseMobileImageUrl || '';
        item.isInternalImg = false;
        item.isTaskRejected = true;
        item.title = `Отменено задание <a href="#!showTask=${value.sourceId}">${value.attributes.ExerciseTitle}</a>`;
        item.time = dayjs.utc(value.operationDate).local().format('HH:mm');
        return item;
      }
      if (value.operationType === 'SportActivity') {
        item.img = ActivityCover3;
        item.isInternalImg = true;
        item.time = dayjs.utc(value.operationDate).local().format('HH:mm');
        item.title = `<a href="/my-settings/trainings">${this.getFormattedDistance(value)}</a>`;
        return item;
      }
      if (value.operationType === 'Achievement') {
        const achievement = this.getAchievement(value);
        item.img = achievement.img;
        item.isInternalImg = true;
        item.achievementType = value.attributes.AchievementType;
        item.title = `Вы разблокировали достижение <b>${achievement.name}</b>`;
        item.time = dayjs.utc(value.operationDate).local().format('HH:mm');
        return item;
      }
      if (value.operationType === 'ParticipantExperienceLevel') {
        item.img = ActivityCoverXP;
        item.isInternalImg = true;
        item.title = `Вы достигли <b>${value.attributes.Level} ур.</b>`;
        item.time = dayjs.utc(value.operationDate).local().format('HH:mm');
        return item;
      }
      item.img = '';
      item.isInternalImg = false;
      item.achievementType = '';
      item.title = '';
      item.time = dayjs.utc(value.operationDate).local().format('HH:mm');
      return item;
    },
    getFormattedTaskState(value) {
      if (!value || !value.attributes) return '';
      if (value.attributes.StateOperation === 'Created') return 'Вы начали задание';
      if (value.attributes.StateOperation === 'Completed') return 'Вы успешно выполнили задание';
      return '';
    },

    getAchievement(value) {
      if (!value || !value.attributes) return '';
      const found = this.achievementsAll.filter((i) => i.achievementType === value.attributes.AchievementType);
      if (found.length === 0) return { name: '', img: '' };
      return found[0];
    },
    getActivity() {
      this.participantId = this.$route.params.participantId;
      if (this.participantId) {
        this.getActivityOperations(this.participantId)
          .then(() => {
            if (this.participantOperationshistory) {
              this.activities = this.getMapppedOperations(this.participantOperationshistory);
              setTimeout(() => { this.loading = false; }, 2000);
            }
          });
      }
    },
    getRating() {
      this.loading = true;
      this.participantId = this.$route.params.participantId;
      if (this.participantId) {
        this.getParticipantRatings(this.participantId)
          .then(() => {
            if (this.participantRatings) {
              setTimeout(() => { this.loading = false; }, 2000);
            }
          });
      }
    },
    getRatingTeam() {
      this.loading = true;
      this.participantId = this.$route.params.participantId;
      if (this.participantId) {
        this.getParticipantTeamRatings(this.participantId)
          .then(() => {
            if (this.participantTeamRatings) {
              setTimeout(() => { this.loading = false; }, 2000);
            }
          });
      }
    },
    getStatistic() {
      this.participantId = this.$route.params.participantId;
      if (this.participantId) {
        this.getParticipantStatistic(this.participantId).then(() => {
          if (this.participantStatistics) {
            this.statistics = this.participantStatistics;
            this.setActivities();
            this.getAchievements();
          }
        });
      }
    },
    getSportIndex() {
      this.participantId = this.$route.params.participantId;
      if (this.participantId) {
        this.getParticipantSportIndex(this.participantId);
      }
    },
    getDistanceStatistics() {
      this.participantId = this.$route.params.participantId;
      if (this.participantId) {
        this.getParticipantDistance(this.participantId);
      }
    },
    getAchievements() {
      this.participantId = this.$route.params.participantId;
      if (this.participantId) {
        this.getParticipantAchievement(this.participantId);
      }
    },
    pagePrev() {
      this.$router.push({ name: 'Participants' });
    },
    calculateAge(birthDate) {
      const currentDate = new Date();
      const birthYear = new Date(birthDate).getFullYear();
      const age = currentDate.getFullYear() - birthYear;
      let yearsStr = 'лет';
      if (age % 10 === 1 && age % 100 !== 11) {
        yearsStr = 'год';
      } else if (age % 10 >= 2 && age % 10 <= 4 && (age % 100 < 10 || age % 100 >= 20)) {
        yearsStr = 'года';
      }
      return `${age} ${yearsStr}`;
    },
    onChangeTab(index) {
      this.loading = true;
      this.selectedTab = index;
      if (index === 0) {
        this.loadDataForFirstTab();
      } else if (index === 1) {
        this.loadDataForSecondTab();
      } else if (index === 2) {
        this.loadDataForThreeTab();
      }
    },
    loadDataForFirstTab() {
      this.getActivity();
    },
    loadDataForSecondTab() {
      this.getRating();
      this.getRatingTeam();
      this.selectedNominationType = 0;
      if (this.participantRatings && this.participantRatings.length > 0) {
        this.selectedNominationType = 0;
        setTimeout(() => {
          this.loading = false;
          this.nominationsUpdating = false;
        }, 1000);
      } else {
        this.selectedNominationType = 1;
      }
    },
    loadDataForThreeTab() {
      this.loading = true;
      this.getStatistic();
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    },
    isVisible(selectedTab) {
      // if (this.$root.isMobile) return true;
      if (selectedTab === this.selectedTab) return true;
      return false;
    },
    getAvatarImage(avatar) {
      if (!avatar) return '';
      if (!avatar.type) return '';
      if (avatar.type === 'custom') {
        return avatar.url || '';
      }
      const found = Avatars.filter((i) => i.id === avatar.type);
      if (found.length === 0) return '';
      return found[0].img || '';
    },
    getParticipantData() {
      const { participantId } = this.$route.params;
      this.getParticipant(participantId)
        .then(() => {
          if (this.participant) {
            this.participantData = this.participant;
          }
        });
    },
    copyUrlToClipboard() {
      const temporaryElement = document.createElement('textarea');
      temporaryElement.value = this.url;
      document.body.appendChild(temporaryElement);
      temporaryElement.select();
      document.execCommand('copy');
      document.body.removeChild(temporaryElement);
    },
  },
  mounted() {
    this.getProfile()
      .then(() => {
        this.getParticipantData();
        this.getRating();
        this.getActivity();
        this.getIndexes();
        this.getSportIndex();
        this.getDistanceStatistics();
        this.getAchievements();
      });
    this.getBalance();
  },
};
</script>

<style lang="scss" scoped>
  @import "./ParticipantItem";
  @import "./Dashboard";

</style>
