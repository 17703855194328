export default [
  {
    achievementType: 'Welcome', class: 'common-welcome', cat: 'common', name: 'Первый подход', desc: 'Добро пожаловать! Первая авторизация – первая награда.',
  },
  {
    achievementType: 'TrackerConnected', class: 'common-tracker', cat: 'common', name: 'Измеритель', desc: 'Подключите трекер активности',
  },
  {
    achievementType: 'FirstWalking', class: 'walking-first', cat: 'walking', name: 'Первая тренировка', desc: 'Выполнил первую тренировку по ходьбе',
  },
  {
    achievementType: 'Walked42KilometersAtATime', class: 'walking-42atOnce', cat: 'walking', name: '42 за раз', desc: 'Прошел 42 км за одну тренировку',
  },
  {
    achievementType: 'Walked25Kilometers', class: 'walking-total25', cat: 'walking', name: '25 км', desc: 'Суммарно прошел 25 км за несколько или одну тренировку',
  },
  {
    achievementType: 'Walked50Kilometers', class: 'walking-total50', cat: 'walking', name: '50 км', desc: 'Суммарно прошел 50 км за несколько или одну тренировку',
  },
  {
    achievementType: 'Walked100Kilometers', class: 'walking-total100', cat: 'walking', name: '100 км', desc: 'Суммарно прошел 100 км за несколько тренировок',
  },
  {
    achievementType: 'Walked300Kilometers', class: 'walking-total300', cat: 'walking', name: '300 км', desc: 'Суммарно прошел 300 км за несколько тренировок',
  },
  {
    achievementType: 'Walked500Kilometers', class: 'walking-total500', cat: 'walking', name: '500 км', desc: 'Суммарно прошел 500 км за несколько тренировок',
  },
  {
    achievementType: 'FirstRunning', class: 'running-first', cat: 'running', name: 'Первый забег', desc: 'Выполнил первую тренировку по бегу',
  },
  {
    achievementType: 'Run42KilometersAtATime', class: 'running-42atOnce', cat: 'running', name: '42 за раз', desc: 'Пробежал 42 км за одну тренировку',
  },
  {
    achievementType: 'Run100KilometersAtATime', class: 'running-100atOnce', cat: 'running', name: '100 за раз', desc: 'Пробежал 100 км за одну тренировку',
  },
  {
    achievementType: 'Run25Kilometers', class: 'running-total25', cat: 'running', name: '25 км', desc: 'Суммарно пробежал 25 км за несколько или одну тренировку',
  },
  {
    achievementType: 'Run50Kilometers', class: 'running-total50', cat: 'running', name: '50 км', desc: 'Суммарно пробежал 50 км за несколько или одну тренировку',
  },
  {
    achievementType: 'Run100Kilometers', class: 'running-total100', cat: 'running', name: '100 км', desc: 'Суммарно пробежал 100 км за несколько или одну тренировку',
  },
  {
    achievementType: 'Run300Kilometers', class: 'running-total300', cat: 'running', name: '300 км', desc: 'Суммарно пробежал 300 км за несколько или одну тренировку',
  },
  {
    achievementType: 'Run500Kilometers', class: 'running-total500', cat: 'running', name: '500 км', desc: 'Суммарно пробежал 500 км за несколько или одну тренировку',
  },
  {
    achievementType: 'FirstCycling', class: 'cycling-first', cat: 'cycling', name: 'Первая тренировка', desc: 'Выполнил первую тренировку на велосипеде',
  },
  {
    achievementType: 'Cycled100KilometersAtATime', class: 'cycling-100atOnce', cat: 'cycling', name: '100 за раз', desc: 'Проехал 100 км за одну тренировку',
  },
  {
    achievementType: 'Cycled50Kilometers', class: 'cycling-total50', cat: 'cycling', name: '50 км', desc: 'Суммарно проехал 50 км за несколько или одну тренировку',
  },
  {
    achievementType: 'Cycled100Kilometers', class: 'cycling-total100', cat: 'cycling', name: '100 км', desc: 'Суммарно проехал 100 км за несколько или одну тренировку',
  },
  {
    achievementType: 'Cycled500Kilometers', class: 'cycling-total500', cat: 'cycling', name: '500 км', desc: 'Суммарно проехал 500 км за несколько или одну тренировку',
  },
  {
    achievementType: 'FirstSwimming', class: 'swimming-first', cat: 'swimming', name: 'Первая тренировка', desc: 'Выполнил первую тренировку по плаванию',
  },
  {
    achievementType: 'Swimming5KilometersAtATime', class: 'swimming-5atOnce', cat: 'swimming', name: '5 за раз', desc: 'Проплыл 5 км за одну тренировку',
  },
  {
    achievementType: 'Swimming10KilometersAtATime', class: 'swimming-10atOnce', cat: 'swimming', name: '10 за раз', desc: 'Проплыл 10 км за одну тренировку',
  },
  {
    achievementType: 'Swimming10Kilometers', class: 'swimming-total10', cat: 'swimming', name: '10 км', desc: 'Суммарно проплыл 10 км за несколько или одну тренировку',
  },
  {
    achievementType: 'Swimming20Kilometers', class: 'swimming-total20', cat: 'swimming', name: '20 км', desc: 'Суммарно проплыл 20 км за несколько или одну тренировку',
  },
  {
    achievementType: 'Swimming50Kilometers', class: 'swimming-total50', cat: 'swimming', name: '50 км', desc: 'Суммарно проплыл 50 км за несколько или одну тренировку',
  },
  {
    achievementType: 'FirstSkiing', class: 'skiing-first', cat: 'skiing', name: 'Первая тренировка', desc: 'Выполнил первую тренировку на лыжах',
  },
  {
    achievementType: 'Skiing50KilometersAtATime', class: 'skiing-50atOnce', cat: 'skiing', name: '50 за раз', desc: 'Преодолел 50 км за одну тренировку',
  },
  {
    achievementType: 'Skiing50Kilometers', class: 'skiing-total50', cat: 'skiing', name: '50 км', desc: 'Суммарно преодолел 50 км за несколько или одну тренировку',
  },
  {
    achievementType: 'Skiing100Kilometers', class: 'skiing-total100', cat: 'skiing', name: '100 км', desc: 'Суммарно преодолел 100 км за несколько или одну тренировку',
  },
  {
    achievementType: 'Skiing300Kilometers', class: 'skiing-total300', cat: 'skiing', name: '300 км', desc: 'Суммарно преодолел 300 км за несколько или одну тренировку',
  },
  {
    achievementType: 'FirstMarketplaceBuy', class: 'market-1stBuy', cat: 'market', name: 'Первая покупка', desc: 'Совершил первую покупку на Маркете',
  },
  {
    achievementType: 'First100Balance', class: 'market-100coins', cat: 'market', name: 'Накопил 100Б', desc: 'Накопил первые 100 баллов',
  },
  {
    achievementType: 'First1000Balance', class: 'market-1000coins', cat: 'market', name: 'Накопил 1000Б', desc: 'Накопил первую 1000 баллов',
  },
];
