/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import marketApi from '@/services/market-api';

const state = {
  promotions: null,
  promotion: null,
  purchaseId: null,
  promocode: null,
  priceRange: null,
  promotionTypes: null,
  partners: null,
  categories: null,
  responseStatus: null,
  errorCode: null,
};

const mutations = {
  setPromotions(state, value) {
    state.promotions = value;
  },
  setPromotion(state, value) {
    state.promotion = value;
  },
  setPromocode(state, value) {
    state.promocode = value;
  },
  setPriceRange(state, value) {
    state.priceRange = value;
  },
  setPromotionTypes(state, value) {
    state.promotionTypes = value;
  },
  setPartners(state, value) {
    state.partners = value;
  },
  setCategories(state, value) {
    state.categories = value.values ? value.values : [];
  },
  setResponseStatus(state, value) {
    state.responseStatus = value;
  },
  setErrorCode(state, value) {
    state.errorCode = value;
  },
  setPurchaseId(state, value) {
    state.purchaseId = value;
  },
};

const getters = {
  promotions: () => state.promotions,
  promotion: () => state.promotion,
  promocode: () => state.promocode,
  priceRange: () => state.priceRange,
  promotionTypes: () => state.promotionTypes,
  partners: () => state.partners,
  categories: () => state.categories,
  responseStatus: () => state.responseStatus,
  errorCode: () => state.errorCode,
  purchaseId: () => state.purchaseId,
};

const actions = {
  buyPromotion({ commit }, { promotionId, purchaseId }) {
    return marketApi.buyPromotion({ promotionId, purchaseId })
      .then((response) => {
        commit('setResponseStatus', response.status);
        commit('setErrorCode', 0);
        commit('setPurchaseId', response.status === 200 ? response.data : null);
      })
      .catch((error) => {
        commit('setPurchaseId', null);
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
        commit('setErrorCode', error.response && error.response.data ? error.response.data.code || 0 : 0);
      });
  },
  getPromocodes({ commit }, purchaseId) {
    return marketApi.getPromocodes({ purchaseId })
      .then((response) => {
        commit('setResponseStatus', response.status);
        if (response.status === 200) {
          commit('setPromocode', response.data.code);
        } else {
          commit('setPromocode', null);
        }
      })
      .catch((error) => {
        commit('setPromocode', null);
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
      });
  },
  getPromotions({ commit }, {
    offset, count, sort, filters,
  }) {
    return marketApi.getPromotions({
      offset, count, sort, filters,
    })
      .then((response) => {
        commit('setResponseStatus', response.status);
        if (response.status === 200) {
          commit('setPromotions', response.data);
        } else {
          commit('setPromotions', null);
        }
      })
      .catch((error) => {
        commit('setPromotions', null);
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
      });
  },
  getPromotion({ commit }, promotionId) {
    return marketApi.getPromotion({ promotionId })
      .then((response) => {
        commit('setResponseStatus', response.status);
        if (response.status === 200) {
          commit('setPromotion', response.data);
        } else {
          commit('setPromotion', null);
        }
      })
      .catch((error) => {
        commit('setPromotion', null);
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
      });
  },
  getPriceRange({ commit }) {
    return marketApi.getPriceRange()
      .then((response) => {
        commit('setResponseStatus', response.status);
        if (response.status === 200) {
          commit('setPriceRange', response.data);
        } else {
          commit('setPriceRange', null);
        }
      })
      .catch((error) => {
        commit('setPriceRange', null);
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
      });
  },
  getPromotionTypes({ commit }) {
    return marketApi.getPromotionTypes()
      .then((response) => {
        commit('setResponseStatus', response.status);
        if (response.status === 200) {
          commit('setPromotionTypes', response.data);
        } else {
          commit('setPromotionTypes', null);
        }
      })
      .catch((error) => {
        commit('setPromotionTypes', null);
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
      });
  },
  getPartners({ commit }) {
    return marketApi.getPartners()
      .then((response) => {
        commit('setResponseStatus', response.status);
        if (response.status === 200) {
          commit('setPartners', response.data);
        } else {
          commit('setPartners', null);
        }
      })
      .catch((error) => {
        commit('setPartners', null);
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
      });
  },
  getCategories({ commit }, { offset, count, sort }) {
    return marketApi.getCategories({ offset, count, sort })
      .then((response) => {
        commit('setResponseStatus', response.status);
        if (response.status === 200) {
          commit('setCategories', response.data);
        } else {
          commit('setCategories', null);
        }
      })
      .catch((error) => {
        commit('setCategories', null);
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
