/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import scountApi from '@/services/scount-api';

const state = {
  providers: null,
  trackers: null,
  tracker: null,
  connectUrl: null,
  passCode: null,
  errorCode: null,
  responseStatus: null,
};

const mutations = {
  setProviders(state, value) {
    state.providers = value;
  },
  setTrackers(state, value) {
    state.trackers = value;
  },
  setTracker(state, value) {
    state.tracker = value;
  },
  setConnectUrl(state, value) {
    state.connectUrl = value;
  },
  setPassCode(state, value) {
    state.passCode = value;
  },
  setErrorCode(state, value) {
    state.errorCode = value;
  },
  setResponseStatus(state, value) {
    state.responseStatus = value;
  },
};

const getters = {
  providers: (state) => state.providers,
  trackers: (state) => state.trackers,
  tracker: (state) => state.tracker,
  connectUrl: (state) => state.connectUrl,
  passCode: (state) => state.passCode,
  errorCode: (state) => state.errorCode,
  responseStatus: (state) => state.responseStatus,
};

const actions = {
  getProviders({ commit }) {
    return scountApi.getProviders()
      .then((response) => {
        commit('setResponseStatus', response.status);
        if (response.status === 200) {
          commit('setProviders', response.data);
        } else {
          commit('setProviders', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
      });
  },
  getTrackers({ commit }) {
    return scountApi.getTrackers()
      .then((response) => {
        commit('setResponseStatus', response.status);
        if (response.status === 200) {
          commit('setTrackers', response.data);
        } else {
          commit('setTrackers', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
      });
  },
  getTracker({ commit }, trackerId) {
    return scountApi.getTracker({ trackerId })
      .then((response) => {
        commit('setResponseStatus', response.status);
        if (response.status === 200) {
          commit('setTracker', response.data);
        } else {
          commit('setTracker', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
      });
  },
  createTracker({ commit }, {
    trackerId, provider, redirectUrl, parameters,
  }) {
    return scountApi.createTracker({
      trackerId, provider, redirectUrl, parameters,
    })
      .then((response) => {
        commit('setResponseStatus', response.status);
        commit('setConnectUrl', response.data.connectUrl || null);
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
        commit('setConnectUrl', null);
      });
  },
  authTracker({ commit }, { trackerId, parameters }) {
    return scountApi.authTracker({ trackerId, parameters })
      .then((response) => {
        commit('setResponseStatus', response.status);
        commit('setPassCode', response.data.Passcode || null);
        commit('setErrorCode', null);
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
        commit('setPassCode', null);
        commit('setErrorCode', error.response.data.code || null);
      });
  },
  disconnectTracker({ commit }, trackerId) {
    return scountApi.disconnectTracker({ trackerId })
      .then((response) => {
        commit('setResponseStatus', response.status);
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
