<template>
  <div class="trackers">
    <div class="trackers__heading">Трекеры активности</div>
    <div v-if="connectedTracker !== null">
      <div class="trackers__subheading">Подключены</div>
      <div class="trackers__items" style="margin-bottom: 24px;">
        <Tracker
          :provider="connectedTracker && connectedTracker.provider ? connectedTracker.provider.toLowerCase() : ''"
          :loading="loading"
          :active="true"
          disconnectText="X"
          @disconnect="onDisconnect(connectedTracker)"
          :class="{ 'tracker-no-discipline-limit': showGuideSimpleRun, 'none': connectedTracker.provider === 'ScountTracker' }"
        />
      </div>
    </div>
    <div>
      <div class="trackers__subheading">Подключите сейчас</div>
      <div class="trackers__items">
        <Tracker
          v-for="(item, i) in items"
          :key="i"
          :provider="item ? item.name.toLowerCase() : ''"
          :instructionLink="getTrackerInstruction(item.name.toLowerCase())"
          instructionTarget="_blank"
          :hideInstruction="getTrackerInstruction(item.name.toLowerCase()) === '#'"
          :loading="loading"
          @connect="item.name === 'ScountTracker' ? onScountTracker() :onConnect(item.name)"
          connectText="Подключить"
          :class="{
            'd-none': connectedTracker && connectedTracker.provider ? (item.name === connectedTracker.provider) : false,
            'tracker-no-discipline-limit': showGuideSimpleRun,
            'none': item.name === 'ScountTracker' && !isSpecialSettingsMp
          }"
        />
      </div>
      <div v-if="false" class="trackers__request">
        <span>Нужен другой трекер?</span>
        <span>Оставить запрос</span>
      </div>
    </div>

    <Modal
      name="trackers-confirm"
      state="warning"
      :title="`Трекер будет изменён на «${newTrackerName || ''}»`"
      :subtitle="`Тренировки с трекера «${trackerName}» будут сохранены.`"
      :active="showChange"
      :noTransition="true"
      @close.prevent="cancelChange"
    >
      <div class="buttons-group">
        <div class="buttons-group__item">
          <Button
            variant="primary"
            size="lg"
            text="Продолжить"
            @click="confirmChange"
            wide
            mobile
          ></Button>
        </div>
        <div class="buttons-group__item buttons-group__item_first">
          <Button
            variant="secondary"
            size="lg"
            text="Отменить"
            @click="cancelChange"
            wide
            mobile
          ></Button>
        </div>
      </div>
    </Modal>

    <Modal
      name="guide-scount"
      :active="guideScount"
      @close.prevent="guideScount=false"
      :img="scountImg"
    >
    <div class="guide__title guide__title_steps" v-html="guideTitle"></div>
    <div class="guide__subtitle guide__subtitle_steps">
      <b>Шаг 1.</b> Установите приложение
      <div class="guide__platform-link">
      <a v-if="OS === 'unknown'" href="https://apps.apple.com/ru/app/%D1%81%D0%BA%D0%B0%D1%83%D0%BD%D1%82/id6504521298" target="_blank"><img :src="appStoreImg"/></a>
      <a v-if="OS === 'unknown'" href="https://www.rustore.ru/catalog/app/pro.scount.app" target="_blank"><img :src="rustoreImg"/></a>
    </div>
    <div class="guide__platform-link">
      <a v-if="OS === 'iOS'" href="https://apps.apple.com/ru/app/%D1%81%D0%BA%D0%B0%D1%83%D0%BD%D1%82/id6504521298" target="_blank"><img :src="appStoreImg"/></a>
      <a v-if="OS === 'Android'" href="https://www.rustore.ru/catalog/app/pro.scount.app" target="_blank"><img :src="rustoreImg"/></a>
    </div>
    </div>
    <!-- <div v-if="android || iOS" class="guide__platform-link">
      <a :href="android ? appAndroid : appiOS" target="_blank"><img :src="android ? googlePlayImg : appStoreImg"/></a>
    </div> -->
    <div class="guide__subtitle guide__subtitle_steps">
      <b>Шаг 2.</b> Скопируйте этот код и используйте его для входа в приложении
      <div class="guide__passcode guide__passcode_color">
        <span>{{ code ? code.code : '' }}</span>
        <span @click.stop="onCopyCode"><Icon name="copy" /></span>
      </div>
    </div>
    <div class="guide__subtitle guide__subtitle_steps">
      <b>Шаг 3.</b> Разрешите приложению доступ к данным физической активности
      <div>
        <span v-if="OS === 'unknown'"><img :src="healthConnectImg"/></span>
        <span v-if="OS === 'unknown'"><img :src="healthImg"/></span>
        <span v-if="OS === 'Android'"><img :src="healthConnectImg"/></span>
        <span v-if="OS === 'iOS'"><img :src="healthImg"/></span>
      </div>
    </div>
    <div class="sneak-bar" v-if="showCopied">
      <Alert
        text="Код скопирован."
        actionText="Понятно"
        state="success"
        closable
        autoClose
        @close="showCopied = false"
      ></Alert>
    </div>
    </Modal>

    <Finish
      :shown="showFinish"
      :provider="tracker ? tracker.provider : ''"
      :settings="{ noTransition: true, closable: true }"
      @close="close"
    ></Finish>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Alert from '@rr-component-library/alert/src/main';
import Tracker from '@rr-component-library/tracker/src/main';
import Finish from '@/components/Onboarding/steps/Finish.vue';
import trackerInstructions from '@/assets/data/trackers';
import GooglePlayImg from '@/assets/img/connection-guide/google-play.svg';
import AppStoreImg from '@/assets/img/connection-guide/app-store.svg';
import RuStoreImg from '@/assets/img/connection-guide/rustore.svg';
import HealthImg from '@/assets/img/connection-guide/health.svg';
import HealthConnectImg from '@/assets/img/connection-guide/health-connect-app.svg';
import ScountImgMiddle from '@/assets/img/scount-middle.svg';
import { addNonBreakingSpaces } from '@/assets/js/helpers';

const { v4: uuidv4 } = require('uuid');

export default {
  name: 'Trackers',
  components: {
    Tracker,
    Finish,
    Alert,
  },
  data() {
    return {
      loading: false,
      connectedTracker: null,
      trackerName: null,
      newTrackerName: null,
      showChange: false,
      showFinish: false,
      guideScount: false,
      links: trackerInstructions,
      googlePlayImg: GooglePlayImg,
      appStoreImg: AppStoreImg,
      rustoreImg: RuStoreImg,
      healthImg: HealthImg,
      healthConnectImg: HealthConnectImg,
      scountImg: ScountImgMiddle,
      items: [],
      showCopied: false,
    };
  },
  computed: {
    ...mapGetters('settings', ['theme', 'options']),
    ...mapGetters('user', ['isAuthenticated', 'code', 'profile']),
    ...mapGetters('trackers', ['providers', 'tracker', 'trackers', 'responseStatus', 'connectUrl']),
    showGuideSimpleRun() {
      return this.options ? this.options.showGuideSimpleRun === true : false;
    },
    guideTitle() {
      return addNonBreakingSpaces('Установите трекер СКАУНТ на смартфон и войдите по коду');
    },
    isSpecialSettingsMp() {
      return this.options ? this.options.isSpecialSettingsMp === true : false;
    },
    OS() {
      const userAgent = navigator.userAgent || navigator.vendor || (window).opera;
      if (/android/i.test(userAgent)) {
        return 'Android';
      }
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'iOS';
      }
      return 'unknown';
    },
  },
  watch: {
    isAuthenticated(val) {
      if (val) {
        this.update();
      }
    },
    providers(val) {
      if (val) {
        const items = [];
        for (let i = 0; i < this.providers.length; i += 1) {
          if (this.getTrackerInstruction(this.providers[i].toLowerCase()) !== '') {
            items.push({
              name: this.providers[i],
              order: this.getTrackerOrder(this.providers[i].toLowerCase()),
            });
          }
        }
        this.items = items.sort(this.sort);
      }
    },
  },
  methods: {
    ...mapActions('trackers', ['getProviders', 'getTracker', 'getTrackers', 'createTracker', 'disconnectTracker']),
    ...mapActions('user', ['getCode']),
    onScountTracker() {
      this.guideScount = true;
    },
    getCodeMp() {
      if (this.profile) {
        this.getCode(this.profile.marketingProgramId)
          .then(() => {
            if (this.responseStatus === 200) {
              this.loading = false;
            }
          });
      }
    },
    onCopyCode() {
      navigator.clipboard.writeText(this.code.code);
      if (this.showCopied) this.showCopied = false;
      setTimeout(() => { this.showCopied = true; }, 500);
    },
    onConnect(provider) {
      this.loading = true;
      if (this.connectedTracker !== null) {
        if (provider !== this.connectedTracker.provider) {
          this.trackerName = this.connectedTracker.provider || '';
          this.newTrackerName = provider;
          this.showChange = true;
        }
        return;
      }
      if (provider) {
        const trackerId = uuidv4();
        this.createTracker({
          provider,
          trackerId,
          redirectUrl: `${window.location.origin}/my-settings/trackers?connect=${trackerId}`,
          parameters: {
            authorizationType: 'Normal',
          },
        })
          .then(() => {
            if (this.connectUrl) {
              window.location.href = this.connectUrl;
            }
          });
      }
    },
    onChangeTracker(provider) {
      this.disconnectTracker(this.connectedTracker.trackerId)
        .then(() => {
          if (this.responseStatus === 204) {
            this.connectedTracker = null;
            this.onConnect(provider);
          } else {
            this.showChange = false;
            this.loading = false;
          }
        });
    },
    onDisconnect(provider) {
      this.loading = true;
      if (provider) {
        this.disconnectTracker(provider.trackerId)
          .then(() => {
            if (this.responseStatus === 204) {
              this.getTrackers()
                .then(() => {
                  if (this.responseStatus === 200 && this.trackers) {
                    const connected = this.trackers.filter((i) => i.state === 'Connected');
                    if (connected.length > 0) {
                      // eslint-disable-next-line prefer-destructuring
                      this.connectedTracker = connected[0];
                    } else {
                      this.connectedTracker = null;
                    }
                  }
                  this.loading = false;
                });
            } else {
              this.loading = false;
            }
          });
      }
    },
    close() {
      this.showFinish = false;
      localStorage.removeItem('returnToTask');
      window.location.href = '/my-settings/trackers';
    },
    checkTrackerState() {
      if (this.tracker && this.tracker.state === 'Connected') {
        this.loading = false;
        return;
      }
      if (this.$route.query.connect) {
        this.getTracker(this.$route.query.connect)
          .then(() => {
            if (this.responseStatus === 200) {
              if (this.tracker && this.tracker.state === 'Connected') {
                setTimeout(() => {
                  this.showFinish = true;
                }, 400);
              } else if (this.tracker && this.tracker.state !== 'Connected') {
                this.loading = true;
                setTimeout(() => {
                  this.checkTrackerState();
                }, 1000);
              }
            } else {
              window.location.href = '/my-settings/trackers';
            }
          });
      }
    },
    update() {
      this.getProviders();
      this.getTrackers()
        .then(() => {
          if (this.responseStatus === 200 && this.trackers) {
            const connected = this.trackers.filter((i) => i.state === 'Connected');
            if (connected.length > 0) {
              localStorage.removeItem('returnFromGuide');
              // eslint-disable-next-line prefer-destructuring
              this.connectedTracker = connected[0];
            }
          }
        });
    },
    confirmChange() {
      this.showChange = false;
      this.onChangeTracker(this.newTrackerName);
    },
    cancelChange() {
      this.showChange = false;
      this.newTrackerName = null;
      this.loading = false;
    },
    getTrackerInstruction(id) {
      const found = this.links.filter((i) => i.id === id);
      if (found.length === 0) return '';
      if (this.theme === '') return '#';
      if (!this.theme) return '';
      if (!found[0].link) return '';
      if (!found[0].link[this.theme]) return '';
      return found[0].link[this.theme];
    },
    getTrackerOrder(id) {
      const found = this.links.filter((i) => i.id === id);
      if (found.length === 0) return '';
      return found[0].order;
    },
    sort(a, b) {
      if (a.order < b.order) return -1;
      if (a.order > b.order) return 1;
      return 0;
    },
  },
  mounted() {
    this.update();
    this.checkTrackerState();
    this.getCodeMp();
  },
};
</script>

<style lang="scss" scoped>
  @import "./Trackers";
  @import '../ConnectionGuide/ConnectionGuide';

</style>
